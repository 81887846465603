<template>
  <div>
    <b-container fluid>
      <section v-if="docsLoaded">
        <b-row v-if="premiseDocuments.length > 0" class="match-height">
          <b-col
            md="6"
            lg="4"
            xl="3"
            v-for="document in premiseDocuments"
            v-if="document.fileCategory != 'invoice'"
            :key="document.id"
          >
            <b-card no-body>
              <b-card-body class="border-top p-1">
                <b-card-title v-if="document.fileCategory === 'Not Defined'"
                  ><feather-icon icon="FileTextIcon" /> Document</b-card-title
                >
                <b-card-title v-else
                  ><feather-icon icon="FileTextIcon" />
                  {{ document.fileCategory }}</b-card-title
                >
                <b-card-text>
                  <p>{{ document.fileName }}</p>
                  <p class="small">
                    Last Modified: {{ getHumanDate(document.fileModDate) }}
                  </p>
                  <b-button
                    :href="document.filePath"
                    class="mt-1 btn-sm d-block"
                    style="
                      background-color: #00a19a !important;
                      border-color: #00a19a !important;
                      color: #fff !important;
                    "
                    >Open Document</b-button
                  >
                </b-card-text>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col cols="12">
            <p id="no-docs-mesg" class="bg-white p-1 font-weight-bold">
              Your documents haven't been uploaded to your secure space yet or
              you don't have permission to view them.
            </p>
          </b-col>
        </b-row>
      </section>
      <section v-else>
        <b-row>
          <b-col cols="12">
            <div
              class="loading login-page"
              id="login-page-loading"
              style="display: block"
            >
              <div class="effect-1 effects"></div>
              <div class="effect-2 effects"></div>
              <div class="effect-3 effects"></div>
            </div> </b-col
        ></b-row>
      </section>
    </b-container>
  </div>
  <!-- / Coming soon page-->
</template>

<script>
import store from "@/store/index";
import moment from "moment";
import {
  BContainer,
  BRow,
  BButton,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
} from "bootstrap-vue";

export default {
  components: {
    BContainer,
    BRow,
    BButton,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
  },
  data() {
    return {
      docsLoaded: null,
    };
  },
  created: function () {
    if (store.state.app.selectedPremiseDocuments.length < 1) {
      this.getDocuments();
    } else if (store.state.app.selectedPremiseDocuments.length > 0) {
      this.docsLoaded = true;
    }
  },
  watch: {
    // whenever account changes, this function will run
    machineID() {
      this.getDocuments();
    },
  },
  computed: {
    machineID: function () {
      return store.state.app.selectedPremiseID;
    },
    userLevel: function () {
      return store.state.app.selectedUserLevels.toString();
    },
    premiseDocuments: function () {
      return store.state.app.selectedPremiseDocuments;
    },
  },
  methods: {
    getHumanDate: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },
    getDocuments: function () {
      fetch(
        "https://prod-18.uksouth.logic.azure.com:443/workflows/af264fc31d924c9f9a6e31a307beb8a8/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=goxS7ocvC-POlTQq9Q7Aps-VP76M712Kf8-UY-od0yo",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            Accid: this.machineID,
            UsrPm: this.userLevel,
          }),
        }
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("HTTP status " + response.status);
          }
          return response.json();
        })
        .then((data) => {
          if (!data.ErrCode) {
            console.log(data);
            store.commit("app/UPDATE_SELECTED_PREMISE_DOCUMENTS", data);
            localStorage.setItem(
              "selectedPremiseDocuments",
              JSON.stringify(data)
            );
            this.docsLoaded = true;
          } else {
            store.commit("app/UPDATE_SELECTED_PREMISE_DOCUMENTS", []);
            localStorage.removeItem("selectedPremiseDocuments");
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
